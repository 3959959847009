import {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const MainStateContext = createContext(null);

const StateWrapper = ({children}) => {
    const [state, setState] = useState(
        {
            firstLoad: true,
            mobileMenu: false,
        }
    );

    return (
        <MainStateContext.Provider
            value={{
                firstLoad: state.firstLoad,
                mobileMenu: state.mobileMenu,
                setState,
            }}
        >
            {children}
        </MainStateContext.Provider>
    );
};

StateWrapper.propTypes = {
    children: PropTypes.any,
};

export default StateWrapper;
