import {Helmet} from 'react-helmet';
import {TransitionPortal} from 'gatsby-plugin-transition-link';
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook';
import {CookiesConsentWrapper} from '@digitalvisioncz/cookie-consent';
import {LocationProvider} from '@gatsbyjs/reach-router';
import StateWrapper from './src/components/layout/stateWrapper';
import * as styles from './src/components/layout/layout.module.css';
import CookieBanner from './src/components/cookies/cookieBanner';
import GTMWrapper from './src/components/layout/GTMWrapper';

const gtmParams = {
    id: 'GTM-M239H5F',
};

const cookiesParams = {
    cookiePrefix: 'dvCookies',
};

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({element}) => (
    <>
        <Helmet>
            <html lang="cs"/>
            <body data-body/>
            <meta name="theme-color" content="#ffffff"/>
            <meta name="msapplication-TileColor" content="#2d89ef"/>
            <link
                rel="mask-icon"
                href="images/favicons/safari-pinned-tab.svg"
                color="#0072d1"
            />
            <link rel="manifest" href="images/favicons/site.webmanifest"/>
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="images/favicons/apple-touch-icon.png"
            />
            <link rel="icon" href="/images/favicons/favicon.svg"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
            />
            <link rel="stylesheet" href="https://cdn.plyr.io/3.7.2/plyr.css" />
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Source+Sans+Pro:wght@400;700&display=swap"
                rel="stylesheet"
            />
        </Helmet>
        <LocationProvider>
            <StateWrapper>
                <GTMProvider state={gtmParams}>
                    <CookiesConsentWrapper settings={cookiesParams}>
                        <GTMWrapper>
                            {element}
                            <CookieBanner/>
                        </GTMWrapper>
                    </CookiesConsentWrapper>
                </GTMProvider>
            </StateWrapper>
        </LocationProvider>
        <TransitionPortal>
            <div className={styles.pageCrossWrapper} data-crosspage-wrapper>
                <div className={styles.pageCrossLogo} data-crosspage-logo>
                    <svg
                        width="59"
                        height="54"
                        viewBox="0 0 59 54"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g>
                            <path d="M58.6955 6.60853C58.6955 2.95882 55.7352 0 52.0837 0C49.6358 0 47.5004 1.33072 46.3569 3.30727C39.7495 14.7566 29.4334 32.6311 22.8245 44.0789C22.2625 45.0507 21.9395 46.1771 21.9395 47.3802C21.9395 51.0299 24.8998 53.9887 28.5513 53.9887C30.9992 53.9887 33.1346 52.658 34.2781 50.6815C40.8855 39.2322 51.2016 21.3576 57.8105 9.9098C58.3725 8.93804 58.6955 7.81159 58.6955 6.60853Z" fill="#ffc700"/>
                            <path d="M26.4055 22.8328C21.0619 13.5568 21.0619 13.5568 15.7168 4.28235H15.7153C14.2606 1.7621 11.5393 0.0664062 8.41964 0.0664062C3.77028 0.0664062 0 3.83327 0 8.48177C0 25.314 0 28.7474 0 45.5827H0.0030054C0.0015027 47.0125 0.363654 48.4634 1.13003 49.7911C3.45471 53.8163 8.60447 55.1951 12.6317 52.8716C13.9631 52.1026 15.003 51.0257 15.7183 49.7821L15.7198 49.7836C21.0634 40.5076 21.0634 40.5076 26.407 31.2332L26.4055 31.2317C27.8496 28.7174 27.9563 25.5258 26.4055 22.8328Z" fill="#ffe822"/>
                        </g>
                    </svg>
                </div>
                <div
                    className={styles.pageCrossBackground}
                    data-crosspage-background
                />
            </div>
        </TransitionPortal>
    </>
);
