import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {useCookiesConsentContext} from '@digitalvisioncz/cookie-consent';

const dataLayerValues = {
    necessary: {
        code: '_C001_',
        event: 'consentPerformance',
        cookies: ['necessary'],
    },
    statisticsAndPreferences: {
        code: '_C002_',
        event: 'consentAnalytics',
        cookies: ['statistics', 'preferences'],
    },
    marketing: {
        code: '_C003_',
        event: 'consentMarketing',
        cookies: ['marketing'],
    },
};

export const sendGTMData = (sendDataToGTM, acceptedConsentGroups) => {
    const consentValues = acceptedConsentGroups.map(group => dataLayerValues[group].code);

    sendDataToGTM({event: 'cookiesConsent', consentGroup: consentValues.join(' ')});

    acceptedConsentGroups.forEach(group => {
        sendDataToGTM({event: dataLayerValues[group].event});
    });

    sendDataToGTM({event: 'cookiesLoaded'});
};

const GTMWrapper = ({children}) => {
    const sendDataToGTM = useGTMDispatch();
    const {consent: {cookies}} = useCookiesConsentContext();

    useEffect(() => {
        if (!cookies) {
            return;
        }

        const acceptedConsentGroups = [];

        Object.keys(dataLayerValues).forEach(group => {
            if (dataLayerValues[group].cookies.every(cookie => cookies.includes(cookie))) {
                acceptedConsentGroups.push(group);
            }
        });

        sendGTMData(sendDataToGTM, acceptedConsentGroups);
    }, [
        children,
        cookies,
        sendDataToGTM,
    ]);

    return <>{children}</>;
};

GTMWrapper.propTypes = {
    children: PropTypes.any,
};

export default GTMWrapper;
