const yellowBanner = {
    banner: 'var(--color-yellow)',
    button: 'var(--color-blue)',
    buttonText: 'var(--color-white)',
    text: 'var(--color-dv-black)',
};

const blueBanner = {
    banner: 'var(--color-blue)',
    button: 'var(--color-yellow)',
    buttonText: 'var(--color-dv-black)',
    text: 'var(--color-white)',
};

export default {
    '': {
        ...blueBanner,
    },
    cases: {
        ...yellowBanner,
    },
    career: {
        ...yellowBanner,
    },
    contact: {
        ...blueBanner,
    },
};
