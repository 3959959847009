import {useEffect, useState} from 'react';
import {useCookiesConsentContext} from '@digitalvisioncz/cookie-consent';
import {Link, useLocation} from '@gatsbyjs/reach-router';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import cookieBannerTypes from '../../data/cookieBannerTypes';
import * as styles from './cookieBanner.module.css';

const CookieIcon = () => (
    <svg
        viewBox="0 0 60 60"
        className={styles.cookieIcon}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M49 35C49 37.2091 47.2091 39 45 39C42.7909 39 41 37.2091 41 35C41 32.7909 42.7909 31 45 31C47.2091 31 49 32.7909 49 35Z" fill="var( --cookie-banner-text-color)"/>
        <path d="M43 17C43 19.2091 41.2091 21 39 21C36.7909 21 35 19.2091 35 17C35 14.7909 36.7909 13 39 13C41.2091 13 43 14.7909 43 17Z" fill="var( --cookie-banner-text-color)"/>
        <path d="M36 46C36 48.2091 34.2091 50 32 50C29.7909 50 28 48.2091 28 46C28 43.7909 29.7909 42 32 42C34.2091 42 36 43.7909 36 46Z" fill="var( --cookie-banner-text-color)"/>
        <path d="M37 30C37 32.2091 35.2091 34 33 34C30.7909 34 29 32.2091 29 30C29 27.7909 30.7909 26 33 26C35.2091 26 37 27.7909 37 30Z" fill="var( --cookie-banner-text-color)"/>
        <path d="M23 37C23 39.2091 21.2091 41 19 41C16.7909 41 15 39.2091 15 37C15 34.7909 16.7909 33 19 33C21.2091 33 23 34.7909 23 37Z" fill="var( --cookie-banner-text-color)"/>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.785 24.3173C16.6299 29.233 11.8294 32.7222 6.18371 32.9842C7.65298 44.8308 17.7557 54 30 54C43.2548 54 54 43.2548 54 30C54 17.0415 43.7298 6.48164 30.885 6.01602C30.9609 6.66714 31 7.32922 31 8C31 15.7274 25.8443 22.2506 18.785 24.3173ZM24.9041 6.54083C24.6247 4.43294 23.7484 2.51348 22.4507 0.957866C24.5565 0.412031 26.7521 0.0891742 29.0108 0.0160005C29.3392 0.00536081 29.669 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60C14.0845 60 1.06374 47.6065 0.0619755 31.944C0.0208687 31.3013 0 30.6531 0 30C0 28.3932 0.126314 26.816 0.369553 25.2776C1.79536 26.3586 3.57278 27 5.5 27C5.73178 27 5.96139 26.9907 6.18848 26.9725C10.3965 26.6352 13.74 23.2332 13.9855 19L14 19C20.0751 19 25 14.0751 25 8C25 7.50531 24.9673 7.01824 24.9041 6.54083Z"
            fill="var( --cookie-banner-text-color)"
        />
    </svg>
);

const cookieGroupsData = {
    necessary: {
        isNecessary: true,
        cookies: ['necessary'],
        title: 'Nezbytné',
        description: 'Základní technické cookies, které zajišťují, že se web zobrazuje a funguje, jak má. A třeba i to, že se správně odesílají kontaktní formuláře. Tyto cookies není možné vypnout.',
    },
    statisticsAndPreferences: {
        cookies: ['statistics', 'preferences'],
        title: 'Analytické a preferenční',
        description: 'Analytické cookies nám umožňují měřit, kolik uživatelů se na web přišlo podívat, a co je konkrétně zajímalo. Podle toho pak vymýšlíme, jak web vylepšit a kterou část rozšířit (nebo smazat). Preferenční nám pak pomáhají trochu vám web přizpůsobit.',
    },
    marketing: {
        cookies: ['marketing'],
        title: 'Marketing',
        description: 'Zajišťují, že se vám na internetu zobrazuje relevantnější reklama podle toho, co vás zajímá. Když zakážete marketingové cookies, množství reklamy, co na vás vyskakuje napříč internetem, zůstane stejné, jen bude častěji tematicky úplně mimo.',
    },
};
const cookieGroups = Object.keys(cookieGroupsData);

const CookieBanner = () => {
    const location = useLocation();
    const sendDataToGTM = useGTMDispatch();

    const path = location.pathname.split('/')[1];

    const {
        consent: {
            cookies,
            isAccepted,
        },
        acceptSelectedCookies,
        acceptAllCookies,
    } = useCookiesConsentContext();
    const [showAdvancedOptions, setShowAdvancedOption] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        if (!cookies) {
            return;
        }

        setSelectedGroups(cookieGroups.filter(cookieGroup => {
            const cookiesToCheck = cookieGroupsData[cookieGroup].cookies;

            return cookiesToCheck.every(consentKey => cookies.includes(consentKey));
        }));
    }, [cookies]);

    const openAdvancedOptions = () => {
        setShowAdvancedOption(true);
    };

    const saveCookieSettings = () => {
        let cookiesToAccept = [];

        cookiesToAccept = cookiesToAccept.concat.apply(
            [],
            selectedGroups.map(cookieGroup => cookieGroupsData[cookieGroup].cookies)
        );

        acceptSelectedCookies(cookiesToAccept);
    };

    if (isAccepted) {
        return null;
    }

    return (
        <section
            style={cookieBannerTypes[path] ? {
                '--cookie-banner-background-color': cookieBannerTypes[path].banner,
                '--cookie-banner-text-color': cookieBannerTypes[path].text,
                '--cookie-banner-primary-button-background-color': cookieBannerTypes[path].button,
                '--cookie-banner-primary-button-text-color': cookieBannerTypes[path].buttonText,
            } : {}}
            className={styles.cookieBannerWrapper}
        >
            <div className={styles.cookieBanner}>
                <div className={styles.cookieIconArea}>
                    <CookieIcon/>
                </div>
                <div className={styles.cookieTextArea}>
                    <h3 className={styles.cookieTextHeadline}>
                        Napekli jsme vám sušenky
                    </h3>
                    <p className={styles.cookieTextDescription}>
                        Stejně jako naši sladkomilní kolegové, i náš web potřebuje po(u)žívat cookies. Dáte nám k tomu svůj souhlas?
                    </p>
                </div>
                <div className={styles.cookieButtonArea}>
                    <div className={styles.cookieButtons}>
                        <button
                            className={styles.cookiePrimaryButton}
                            onClick={acceptAllCookies}
                        >
                            Povolit vše
                        </button>
                        {!showAdvancedOptions && (
                            <button
                                className={styles.cookieAdvancedOptionsLink}
                                onClick={openAdvancedOptions}
                            >
                                Podrobné nastavení cookies
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.cookieFormArea}>
                    {showAdvancedOptions && (
                        <div className={styles.cookieForm}>
                            {cookieGroups && cookieGroups.map(cookieGroup => {
                                const {
                                    isNecessary,
                                    title,
                                    description,
                                } = cookieGroupsData[cookieGroup];
                                const isChecked = isNecessary || selectedGroups.includes(cookieGroup);
                                const handleCookieOptionChange = () => {
                                    if (isNecessary) {
                                        return;
                                    }

                                    setSelectedGroups(prevState => {
                                        const newState = [...prevState];
                                        const isNewToArray = !newState.includes(cookieGroup);

                                        if (isNewToArray) {
                                            newState.push(cookieGroup);
                                        }

                                        if (!isNewToArray) {
                                            newState.splice(newState.indexOf(cookieGroup), 1);
                                        }

                                        return newState;
                                    });
                                };

                                return (
                                    <div
                                        key={`cookieOption-${cookieGroup}`}
                                        className={styles.cookieFormRow}
                                    >
                                        <label
                                            htmlFor={`cookieOption-${cookieGroup}`}
                                            className={styles.checkbox}
                                        >
                                            <input
                                                type="checkbox"
                                                id={`cookieOption-${cookieGroup}`}
                                                name={cookieGroup}
                                                checked={isChecked}
                                                onChange={handleCookieOptionChange}
                                                disabled={isNecessary}
                                            />
                                            <span className={styles.customCheckbox}/>
                                            <h5 className={styles.cookieFormRowTitle}>
                                                {title}
                                            </h5>
                                        </label>
                                        <p className={styles.cookieFormRowDescription}>
                                            {description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                {showAdvancedOptions && (
                    <div className={styles.cookieSecondaryButtonArea}>
                        <button
                            className={styles.cookieSecondaryButton}
                            onClick={saveCookieSettings}
                        >
                            Uložit nastavení
                        </button>
                    </div>
                )}
                <div className={styles.cookieAdvancedOptionsLinkArea}>
                    <Link
                        onClick={() => {
                            sendDataToGTM({
                                event: 'linkClick',
                                linkText: 'Cookies',
                                targetURL: '/cookies',
                            });
                        }}
                        to="/cookies"
                        className={styles.cookieAdvancedOptionsLink}
                    >
                        Cookies
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default CookieBanner;
