// extracted by mini-css-extract-plugin
export var checkbox = "cookieBanner-module--checkbox--DaRU4";
export var cookieAdvancedOptionsLink = "cookieBanner-module--cookieAdvancedOptionsLink--mjk-s";
export var cookieAdvancedOptionsLinkArea = "cookieBanner-module--cookieAdvancedOptionsLinkArea--KNRPj";
export var cookieBanner = "cookieBanner-module--cookieBanner--pppPa";
export var cookieBannerWrapper = "cookieBanner-module--cookieBannerWrapper--+CxAB";
export var cookieButtonArea = "cookieBanner-module--cookieButtonArea--EAaLV";
export var cookieButtons = "cookieBanner-module--cookieButtons---1q2x";
export var cookieForm = "cookieBanner-module--cookieForm--VvpjK";
export var cookieFormArea = "cookieBanner-module--cookieFormArea--O9oP+";
export var cookieFormRow = "cookieBanner-module--cookieFormRow--Zktel";
export var cookieFormRowDescription = "cookieBanner-module--cookieFormRowDescription--jrM8H";
export var cookieFormRowTitle = "cookieBanner-module--cookieFormRowTitle--u8wGL";
export var cookieHeadlineArea = "cookieBanner-module--cookieHeadlineArea--G6eKN";
export var cookieIcon = "cookieBanner-module--cookieIcon--Ywez7";
export var cookieIconArea = "cookieBanner-module--cookieIconArea--OjXAy";
export var cookiePrimaryButton = "cookieBanner-module--cookiePrimaryButton--V6p5f";
export var cookieSecondaryButton = "cookieBanner-module--cookieSecondaryButton--qRAYq";
export var cookieSecondaryButtonArea = "cookieBanner-module--cookieSecondaryButtonArea--IjjgI";
export var cookieTextDescription = "cookieBanner-module--cookieTextDescription--8kXfQ";
export var cookieTextHeadline = "cookieBanner-module--cookieTextHeadline--E57Nn";
export var customCheckbox = "cookieBanner-module--customCheckbox--P9mxn";